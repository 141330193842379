import { Accordion, Page } from '@silinfo/front-end-template';
import { HeaderProps } from '@silinfo/front-end-template/lib/esm/components/Header/Header';
import axios, { AxiosError, AxiosResponse } from 'axios';
import { FormikErrors } from 'formik';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import Form from '../../../../components/Form/Form';
import MCCLoading from '../../../../components/MCCLoading';
import { IViolations } from '../../../../services/ResponseInterfaces';
import messageTemplatesService from '../../../../services/Unit/messageTemplates';
import { RootState } from '../../../../store';
import { AppViewType, AuthUser, getOtherOwnerCondition, ViewUnitCampaignManagementType } from '../../../../store/auth';
import { create } from '../../../../store/notification';
import {
    setCommonMessageFields,
    setFlaggedMessageFields,
    setOwnMessageFields,
} from '../../../../store/Unit/messageTemplates';
import {
    checkPageAccess,
    onlyCommonCampaignCondition,
    onlyOwnCampaignCondition,
    userHasCommonCampaignRoleInUnit,
} from '../../../../utils/AccessManagementHelper';
import { PROJECT_NAME, transformApiErrors } from '../../../../utils/AppConst';
import { clientEndpoints } from '../../../../utils/clientEndpoints';
import BaseForm from './BaseForm';
import OpenTestSendButton from './OpenTestSendButton';
import TestEmailDialog from './TestEmailDialog';
import { IMessageTemplatesFormType, initialMessageTemplatesForm } from './types';
import { transformForm } from './utils';
import { useNavigate } from 'react-router-dom';

export interface MessageTemplatesMessageFields {
    id: number;
    messageId: string;
    description: string;
    columnName: string;
}

function getHeader(title: string, back: string, fromCampaignView?: boolean): HeaderProps {
    if (fromCampaignView) {
        return {
            project: PROJECT_NAME,
            title,
            breadcrumbs: {
                'campaign': 'Kampányok',
                [clientEndpoints.unit_campaigns]: 'Kampánylista',
                [back]: 'Kampány megtekintése',
                form: title,
            },
        };
    }

    return {
        project: PROJECT_NAME,
        title,
        breadcrumbs: {
            addresses: 'Sablonok',
            [back]: 'Üzenetsablonok',
            form: title,
        },
    };
}

function getTitle(type: string, id: string | undefined, readonly: boolean | undefined) {
    return `${type === 'complex' ? 'Fejlett ü' : 'Ü'}zenetsablon ${
        id ? (!readonly ? 'szerkesztése' : 'megtekintése') : 'hozzáadása'
    }`;
}

function handleSubmit(
    id: string | undefined,
    ownOnly: boolean | undefined,
    view: AppViewType,
    user: AuthUser | undefined,
    type: string,
) {
    return (form: IMessageTemplatesFormType) => {
        const tranformedForm = transformForm(form);
        const ownerId = ownOnly && view.unitCampaignManagement === ViewUnitCampaignManagementType.Own ? user?.id : null;
        return id
            ? messageTemplatesService.update(id, {
                  ...tranformedForm,
                  ...{ ownerId: ownerId },
              })
            : messageTemplatesService.create({
                  ...tranformedForm,
                  ...{ ownerId: ownerId },
                  ...{ type: type },
              });
    };
}

const getUsedMessageTemplates = (
    form: IMessageTemplatesFormType,
    user: AuthUser | undefined,
    ownMessageFields: MessageTemplatesMessageFields[],
    commonMessageFields: MessageTemplatesMessageFields[],
    flaggedMessageFields: MessageTemplatesMessageFields[],
): MessageTemplatesMessageFields[] => {
    const usedMessageFields: MessageTemplatesMessageFields[] = [];
    if (!user) return usedMessageFields;
    let messageFieldsTemp: MessageTemplatesMessageFields[] = [];

    if (onlyOwnCampaignCondition(user) && !onlyCommonCampaignCondition()) {
        messageFieldsTemp = ownMessageFields;
    } else if (userHasCommonCampaignRoleInUnit(user)) {
        messageFieldsTemp = [...commonMessageFields, ...ownMessageFields];
    } else {
        messageFieldsTemp = commonMessageFields;
    }

    messageFieldsTemp = [...messageFieldsTemp, ...flaggedMessageFields];

    messageFiledsLoop: for (const mfIdx in messageFieldsTemp) {
        if (messageFieldsTemp[mfIdx].messageId === '%ALAIRAS%') {
            continue;
        }
        for (const [_, fValue] of Object.entries(form)) {
            if (typeof fValue === 'string' && fValue.includes(messageFieldsTemp[mfIdx].messageId)) {
                usedMessageFields.push(messageFieldsTemp[mfIdx]);
                continue messageFiledsLoop;
            }
        }
        for (const faIdx in form.attachments) {
            for (const [_, faValue] of Object.entries(form.attachments[faIdx])) {
                if (typeof faValue === 'string' && faValue.includes(messageFieldsTemp[mfIdx].messageId)) {
                    usedMessageFields.push(messageFieldsTemp[mfIdx]);
                    continue messageFiledsLoop;
                }
            }
        }
    }

    const uniqueMessageIds = usedMessageFields.filter(
        (messageField, index, array) =>
            array.findIndex((element) => element.messageId === messageField.messageId) === index,
    );

    return uniqueMessageIds;
};

export default function BaseFormPage(props: {
    ownOnly?: boolean;
    complex?: boolean;
    readonly?: boolean;
    fromCampaignView?: boolean;
}) {
    const { ownOnly, complex, readonly, fromCampaignView } = props;
    const { id, campaignId } = useParams();
    const back = fromCampaignView
        ? clientEndpoints.unit_campaigns_view.replace(':id', campaignId || '')
        : clientEndpoints[`unit${ownOnly || getOtherOwnerCondition() ? '_own' : ''}_message_templates`];
    const { user, view } = useSelector((state: RootState) => state.auth);
    const [form, setForm] = useState<IMessageTemplatesFormType>(
        !ownOnly || view.unitCampaignManagement === ViewUnitCampaignManagementType.Common
            ? { ...initialMessageTemplatesForm, ...{ recipientName: '%VEZETEKNEV% %KERESZTNEV%' } }
            : initialMessageTemplatesForm,
    );
    const [pageLoading, setPageLoading] = useState<number>(1 + +!!id);
    const [isDialogOpen, setIsDialogOpen] = useState(false);
    const [usedMessageTemplates, setUsedMessageTemplates] = useState<MessageTemplatesMessageFields[]>([]);
    const dispatch = useDispatch();
    const { messageFields, ownMessageFields, commonMessageFields, flaggedMessageFields } = useSelector(
        (state: RootState) => state.messageTemplates,
    );
    const [globalErrors, setGlobalErrors] = useState<FormikErrors<Record<string, unknown>>>({});
    const [type, setType] = useState<string>('simple');
    const title = getTitle(type, id, readonly);
    const header: HeaderProps = getHeader(title, back, fromCampaignView);
    const navigate = useNavigate();
    /*const checkEntityAccess = useCallback(
        (res: AxiosResponse) => checkPageAccessByEntityOwner(res, ownOnly || false),
        [ownOnly],
    );
*/
    checkPageAccess(ownOnly || false, readonly || false);

    useEffect(() => {
        if (!user) return;

        if (onlyCommonCampaignCondition()) {
            axios
                .all([
                    messageTemplatesService.getMessageFields({ flaggedOnly: 'true' }),
                    messageTemplatesService.getMessageFields({
                        ownerId: 'null',
                        commonOnly: true,
                        dontNeedFlags: 'true',
                    }),
                ])
                .then((res: AxiosResponse[]) => {
                    dispatch(setFlaggedMessageFields(res[0].data));
                    dispatch(setCommonMessageFields(res[1].data));
                })
                .finally(() => setPageLoading((prev) => prev - 1));
        } else {
            axios
                .all([
                    messageTemplatesService.getMessageFields({ flaggedOnly: 'true' }),
                    /**
                     * FIXME: itt ha csak saját jogai vannak, akkor a commonMessageFields nem kell
                     */
                    messageTemplatesService.getMessageFields({
                        ownerId: 'null',
                        commonOnly: 'true',
                        dontNeedFlags: 'true',
                    }),
                    messageTemplatesService.getMessageFields({ ownerId: user?.id, dontNeedFlags: 'true' }),
                ])
                .then((res: AxiosResponse[]) => {
                    dispatch(setFlaggedMessageFields(res[0].data));
                    dispatch(setCommonMessageFields(res[1].data));
                    dispatch(setOwnMessageFields(res[2].data));
                })
                .finally(() => setPageLoading((prev) => prev - 1));
        }

        if (id) {
            messageTemplatesService
                .get2(id, readonly)
                .then((res: AxiosResponse) => {
                    setForm({ ...res.data, attachments: res.data.attachments || [] });
                    setType(res.data.type);
                })
                .finally(() => setPageLoading((prev) => prev - 1));
        } else {
            setType(complex ? 'complex' : 'simple');
        }
    }, [id, dispatch, ownOnly, complex, user, navigate, readonly]);

    if (pageLoading > 0) return <MCCLoading />;

    return (
        <Page header={header}>
            <Accordion title={header.title}>
                <Form
                    fields={[]}
                    onSubmit={handleSubmit(id, ownOnly, view, user, type)}
                    back={back}
                    initialValues={form}
                    readonly={!!readonly}
                    footerButtons={
                        readonly ? (
                            <></>
                        ) : (
                            <OpenTestSendButton
                                onClick={(formikProps) => {
                                    return messageTemplatesService
                                        .validate({
                                            ...transformForm(formikProps.values),
                                            ...{
                                                ownerId:
                                                    ownOnly &&
                                                    view.unitCampaignManagement === ViewUnitCampaignManagementType.Own
                                                        ? user?.id
                                                        : null,
                                            },
                                        })
                                        .then(() => {
                                            setUsedMessageTemplates(
                                                getUsedMessageTemplates(
                                                    formikProps.values,
                                                    user,
                                                    ownMessageFields,
                                                    commonMessageFields,
                                                    flaggedMessageFields,
                                                ),
                                            );
                                            setForm(formikProps.values);
                                            setIsDialogOpen(true);
                                        })
                                        .catch((error: AxiosError<{ violations: IViolations[] }>) => {
                                            if (error.response?.status === 422) {
                                                formikProps.setErrors(
                                                    transformApiErrors<IMessageTemplatesFormType>(
                                                        error.response?.data.violations,
                                                    ),
                                                );
                                            }
                                            dispatch(
                                                create({
                                                    type: 'error',
                                                    message: 'Hiba az üzenetsablon kiküldése során.',
                                                }),
                                            );
                                        });
                                }}
                            />
                        )
                    }
                >
                    {(props) => (
                        <BaseForm
                            {...props}
                            messageFields={[
                                ...messageFields,
                                ...ownMessageFields,
                                ...commonMessageFields,
                                ...flaggedMessageFields,
                            ].filter((mf, idx, self) => idx === self.findIndex((mf2) => mf2.id === mf.id))}
                            globalErrors={globalErrors}
                            complex={type === 'complex'}
                            readonly={readonly}
                        />
                    )}
                </Form>
            </Accordion>
            <TestEmailDialog
                messageTemplatesForm={form}
                messageTemplatesFields={usedMessageTemplates}
                open={isDialogOpen}
                onClose={() => setIsDialogOpen(false)}
                setGlobalErrors={setGlobalErrors}
            />
        </Page>
    );
}
